<template>
  <v-app>
    <v-content
      class="fondo-login"
      style="height: 100%; position: absolute; width: 100%; overflow-y: auto"
    >
      <v-container class="fill-height" fluid>
        <v-row justify="center">
          <v-col
            cols="12"
            lg="4"
            class="shadow-log rounded"
            style="border-radius: 10px; margin-left: 5px; background-color: #fff"
          >
            <v-row justify="center">
              <v-col v-show="mostrarError" cols="12" sm="10">
                <v-alert
                  align="center"
                  animated
                  type="error"
                  style="padding: 0px; margin-top: 10px"
                >
                  Usuario o contraseña incorrectos.
                </v-alert>
              </v-col>
              <v-col class="text-center m-4" cols="12" sm="10">
                  <v-img src="@/assets/logo_login.png" max-height="100"/>
              </v-col>
              <v-col class="text-center" cols="12" sm="10">
                <h2 style="color: #7a7b7b">Inicio de sesión</h2>
              </v-col>
              <v-col cols="12" sm="10">
                <v-form>
                  <validation-observer ref="login">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-text-field
                        prepend-icon="fas fa-user"
                        color="success"
                        v-model="rfc"
                        label="RFC"
                        :error-messages="errors[0]"
                      />
                    </validation-provider>
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-text-field
                        @keypress.enter="iniciarSesion"
                        prepend-icon="fas fa-lock"
                        name="password"
                        label="Contraseña"
                        :append-icon="value_l ? 'mdi-eye-outline' : 'mdi-eye-off'"
                        @click:append="() => (value_l = !value_l)"
                        :type="value_l ? 'password' : 'text'"
                        :error-messages="errors[0]"
                        v-model="password"
                      ></v-text-field>
                    </validation-provider>
                    <v-row class="text-center">
                      <label for="">¿Olvidaste tu contraseña?</label
                      ><a href="#" @click="openDialogRecoverPassword">Haz click aquí</a>
                    </v-row>
                  </validation-observer>
                  <v-btn @click="iniciarSesion" class="mt-5 btn-log" block
                    >Ingresar</v-btn
                  >
                </v-form>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <DialogRecoverPassword v-model="dialogRecover"></DialogRecoverPassword>
      <DialogResetPassword v-model="dialogReset"></DialogResetPassword>

      <v-overlay opacity=".6" absolute :value="value">
        <v-row>
          <v-col cols="12" sm="12">
            <v-row justify="center">
              <v-col cols="4" sm="3">
                <v-progress-circular :size="70" color="white" indeterminate />
              </v-col>
            </v-row>
          </v-col>
          <v-col class="text-center offset-md-1" cols="12" sm="12">
            <strong>Validando crendeciales...</strong>
          </v-col>
        </v-row>
      </v-overlay>
    </v-content>
  </v-app>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import DialogRecoverPassword from "./Components/RecoverPassword.vue";
import DialogResetPassword from "./Components/ResetPassword.vue";

export default {
  components: {
    DialogRecoverPassword,
    DialogResetPassword
  },
  data: function () {
    return {
      rfc: null,
      password: null,
      dialog: true,
      mostrarError: false,
      value: false,
      value_l: String,
      dialogRecover: false,
      dialogReset:false
    };
  },
  watch: {
    rfc(newVal) {
      if (newVal.length > 0) {
        this.rfc = newVal.toUpperCase();
      }
    },
  },
  computed: {},
  async mounted() {
    this.$NProgress.done();
  },
  methods: {
    iniciarSesion() {
      this.mostrarError = false;
      this.$refs.login.validate().then(async (valid) => {
        if (valid) {
          let form = new FormData();
          form.append("usuario", this.rfc);
          form.append("password", this.password);
          this.value = true;

          try {
            let response = await this.$http.post("login", form);
            if (response.data.response) {
              this.$http.defaults.headers.common["Authorization"] = response.data.token;
              setTimeout(() => {
                this.value = false;
                localStorage.setItem("token", response.data.token);
                this.$store.commit("setToken", response.data.token.split(" ")[1]);
                this.$store.commit("setUser", response.data.user);
                this.$store.commit("setAutenticated", true);
                this.$store.commit(
                  "setActiveHistorialComprobantes",
                  response.data.active_historial_comprobantes
                );
                if (response.data.user.initial_password == "0") {
                  this.$router.push({ name: "Comprobantes" });
                } else {
                  this.$router.push({ name: "Perfil de usuario" });
                }
              }, 1500);
            } else {
              setTimeout(() => {
                this.mostrarError = true;
                this.value = false;
              }, 1500);
              setTimeout(() => {
                this.mostrarError = false;
              }, 5000);
            }
          } catch (error) {
            setTimeout(() => {
              this.mostrarError = true;
              this.value = false;
            }, 1500);
          }
        }
      });
    },

    openDialogRecoverPassword() {
      this.dialogRecover = true;
    },
  },
};
</script>
<style>
/* .v-responsive__sizer {
  padding-bottom: 80.063% !important;
}
.v-image__image--cover {
  background-size: contain !important;
} */
.justify-center {
  justify-content: center;
}

.text-input {
  color: #1ab394;
}

.text-input-error {
  color: red;
}
</style>
