<template>
  <v-container fluid>
    <v-dialog v-model="value" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Recuperar contraseña</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <validation-observer ref="formRecoverPassword">
              <v-row>
                <v-col cols="12" sm="12">
                  <validation-provider rules="required|email" v-slot="{ errors }">
                    <v-text-field
                      :error-messages="errors[0]"
                      v-model="email"
                      name="input-10-2"
                      label="Correo electrónico"
                      color="blue darken-1"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </validation-observer>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="d-flex justify-center" cols="12" sm="12">
            <v-btn
              class="mr-3"
              outlined
              color="blue darken-2"
              small
              @click="closeDialog"
              >Cancelar</v-btn
            >
            <v-btn color="blue darken-2" small @click="sendEmailRecover">Enviar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      email: null,
      overlay:false
    };
  },
  watch:{
    value(newVal){
      this.email = null
      this.$refs.formRecoverPassword?.reset()
    }
  },
  methods:{
    closeDialog(){
      this.$emit('input',false)
    },
    sendEmailRecover(){
      this.$refs.formRecoverPassword.validate().then(async (valid) => {
          if(valid){
            this.closeDialog()
            try{
              let form = new FormData();
              form.append("email",this.email);
              this.overlay = true;
              let response = await this.$http.post("recover-password",form);
              if(response.data.response){
                setTimeout(() => {
                  this.overlay = false;
                   this.$swal({
                    title: "Se ha enviado un correo de recuperación",
                    text: "",
                    icon: "success",
                    button:"Aceptar"
                  })
                }, 1500);
              }else {
                setTimeout(() => {
                  this.overlay = false;
                  this.$swal({
                    title: response.data.message,
                    text: "",
                    icon: "error",
                    button:"Aceptar"
                  }).then(() => this.$emit('input',true));
                }, 1500);
              }

            }catch(error){
              setTimeout(() => {
                  this.overlay = false;
                  this.$swal({
                    title: "Ocurrio un error :(",
                    text: "",
                    icon: "error",
                    button:"Aceptar"
                  });
                }, 1500);
            }
          }

        });
    }
  }
};
</script>

<style></style>
